// PaintComponent.js
import React, { useState, useRef } from 'react';

const Line = () => {
  const [isPainting, setIsPainting] = useState(false);
  const [lines, setLines] = useState([]);
  const canvasRef = useRef();

  const startPaint = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setIsPainting(true);
    setLines([...lines, { x1: x, y1: y, x2: x, y2: y }]);
  };

  const paint = (event) => {
    if (!isPainting) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const newLines = [...lines];
    const lastLine = newLines[newLines.length - 1];
    lastLine.x2 = x;
    lastLine.y2 = y;
    setLines(newLines);
  };

  const stopPaint = () => {
    setIsPainting(false);
  };

  return (
    <svg
      ref={canvasRef}
      width="100vw"
      height="100vh"
      onMouseDown={startPaint}
      onMouseMove={paint}
      onMouseUp={stopPaint}
      onMouseLeave={stopPaint}
      style={{ cursor: 'crosshair', background: 'white' }}
    >
      {lines.map((line, index) => (
        <line
          key={index}
          x1={line.x1}
          y1={line.y1}
          x2={line.x2}
          y2={line.y2}
          stroke="black"
          strokeWidth="2"
        />
      ))}
    </svg>
  );
}

export default Line;
