import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';

function RotatingObject() {
  const meshRef = useRef();
  const [color, setColor] = useState("#ff0000"); // Initial color

  // Change color every second
  useEffect(() => {
    const interval = setInterval(() => {
      setColor(`#${Math.floor(Math.random()*16777215).toString(16)}`);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useFrame(() => {
    meshRef.current.rotation.x += 0.01;
    meshRef.current.rotation.y += 0.01;
  });

  return (
    <mesh ref={meshRef}>
      <boxGeometry args={[2, 2, 2]} />
      <meshStandardMaterial color={color} />
    </mesh>
  );
}

function ColorfulScene() {
  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 15, 10]} angle={0.3} />
      <RotatingObject />
    </Canvas>
  );
}

export default ColorfulScene;
