import './UnderConstruction.module.css';

const Excel = () => {
    return (
        <main>
            <h1>Under Construction</h1>
            <p>Check back later for updates!</p>
        </main>
    );
}

export default Excel;