import styles from './HappyHoli.module.css';

const HappyHoli = () => {
    return (
        <div className={styles.background}>
            <div className={styles.layer}></div>
            <div className={styles.layer}></div>
            <div className={styles.layer}></div>
            <div className={styles.text}><b>H@ppy Ho!i</b></div>
        </div>
    );
}

export default HappyHoli;