import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import UnderConstruction from './components/UnderConstruction';
import HappyHoli from './components/HappyHoli';
import RotatingObject from './components/3D';
import Line from './components/Line';
import Drop from './components/Drop';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/underconstruction" />} />

        <Route path="/underconstruction" element={<UnderConstruction />} />

        <Route path="/happyholi" element={<HappyHoli />} />

        <Route path="/paint" element={<Line />} />

        <Route path="/drop" element={<Drop />} />

        <Route path="/3d" element={<RotatingObject />} />

        <Route path="*" element={<Navigate replace to="/underconstruction" />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
