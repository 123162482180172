import React, { useState } from 'react';
import styles from './Drop.module.css'; // Adjust the path as needed

function DropCircle() {
    const [circles, setCircles] = useState([]);

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const dropCircle = (event) => {
        const x = event.clientX;
        const y = event.clientY;

        const newCircle = {
            id: Date.now(),
            color: getRandomColor(),
            finalX: x,
            finalY: y,
        };

        setCircles(currentCircles => [...currentCircles, newCircle]);
    };

    return (
        <div onClick={dropCircle} style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            {circles.map((circle) => (
                <div
                    key={circle.id}
                    className={styles.circle}
                    style={{
                        left: circle.finalX - 25,
                        backgroundColor: circle.color,
                        // Using CSS variable for dynamic end position
                        '--final-top': `${circle.finalY - 25}px`,
                    }}
                ></div>
            ))}
        </div>
    );
}

export default DropCircle;
